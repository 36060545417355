import React from 'react';
import IconCircle from './icons/IconCircle';

import './Motto.css';

const Motto: React.FunctionComponent = () => {
    return (
        <div className="motto">
            <span>simplified</span>
            <IconCircle />
            <span>rental</span>
            <IconCircle />
            <span>management</span>
        </div>
    );
};

export default Motto;