import React from 'react';

import './Nav.css';

const Nav: React.FunctionComponent = () => {
    return (
        <nav>
            <div></div>
            <button>coming soon</button>
        </nav>
    )
};

export default Nav;