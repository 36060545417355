import React from 'react';

/**
 * IconBleuHoldingsLogo is the bleu holdings logo.
 * */
const BleuHoldingsLogo: React.FunctionComponent = () => {
    return (
        <svg id="bleuhold-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3460.076 631">
            <g id="bleuhold-logo-2" data-name="bleuhold-logo" transform="translate(0 72)">
                <path id="Union_5" data-name="Union 5" d="M99.808,246.461a124.324,124.324,0,0,1-44.7-18.808,125.36,125.36,0,0,1-45.288-55A124.362,124.362,0,0,1,2.54,149.192,125.779,125.779,0,0,1,0,125H0V.008C.359,0,.68,0,.985,0A61.217,61.217,0,0,1,25.124,4.911a61.891,61.891,0,0,1,10.535,5.763,62.554,62.554,0,0,1,9.178,7.632,62.835,62.835,0,0,1,7.573,9.249,62.718,62.718,0,0,1,5.718,10.616A62.437,62.437,0,0,1,62.983,61H63v62h.008c-.005.3-.008.631-.008,1a61.713,61.713,0,0,0,10.589,34.664,62.18,62.18,0,0,0,27.278,22.463A61.617,61.617,0,0,0,125,186c.33,0,.666,0,1-.008v63c-.332,0-.669,0-1,0A125.891,125.891,0,0,1,99.808,246.461Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 0, 39.548)" fill="#598298"/>
                <path id="Union_6" data-name="Union 6" d="M124.582,436H.509C.5,435.641.5,435.318.5,435.014a61.418,61.418,0,0,1,4.911-24.181,62.054,62.054,0,0,1,5.763-10.553A62.513,62.513,0,0,1,61.5,372.909v-.017h64V373a61.706,61.706,0,0,0,34.164-10.586,62.18,62.18,0,0,0,22.463-27.278,61.919,61.919,0,0,0-5.716-58.8,62.18,62.18,0,0,0-27.278-22.463A61.591,61.591,0,0,0,125,249c-.331,0-.668,0-1,.007V249a125.846,125.846,0,0,1-24.192-2.536,124.324,124.324,0,0,1-44.7-18.808,125.36,125.36,0,0,1-45.288-55A124.362,124.362,0,0,1,2.54,149.192,125.832,125.832,0,0,1,0,125H0V.008C.359,0,.68,0,.985,0A61.217,61.217,0,0,1,25.124,4.911a61.891,61.891,0,0,1,10.535,5.763,62.554,62.554,0,0,1,9.178,7.632,62.835,62.835,0,0,1,7.573,9.249,62.718,62.718,0,0,1,5.718,10.616A62.437,62.437,0,0,1,62.983,61H63v62h.008c-.005.3-.008.631-.008,1a61.713,61.713,0,0,0,10.589,34.664,62.18,62.18,0,0,0,27.278,22.463A61.617,61.617,0,0,0,125,186c.33,0,.666,0,1-.008V186a125.8,125.8,0,0,1,24.192,2.536,124.324,124.324,0,0,1,44.7,18.808,125.36,125.36,0,0,1,45.288,55,124.343,124.343,0,0,1,7.284,23.464,126.207,126.207,0,0,1,0,50.384,124.323,124.323,0,0,1-18.808,44.7,125.36,125.36,0,0,1-55,45.288,124.342,124.342,0,0,1-23.464,7.284A125.892,125.892,0,0,1,125.5,436h-.919Z" transform="translate(56.076)" fill="#8baabc"/>
            </g>
            <text id="Bleu_Holdings" data-name="Bleu Holdings" transform="translate(386.076 473)" fill="#707070" font-size="450" font-family="Poppins-Medium, Poppins" font-weight="500"><tspan x="0" y="0">Bleu Holdings</tspan></text>
        </svg>
    );
};

export default BleuHoldingsLogo;