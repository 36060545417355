import React from 'react';

const IconCircle: React.FunctionComponent = () => {
    return (
        <svg id="icon-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68">
            <g id="Circle" fill="#707070" stroke="#707070" stroke-width="1">
                <circle cx="34" cy="34" r="34" stroke="none"/>
                <circle cx="34" cy="34" r="33.5" fill="none"/>
            </g>
        </svg>
    );
};

export default IconCircle;