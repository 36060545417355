import React from 'react';
// import logo from './logo.svg';
// import { Counter } from './features/counter/Counter';
import Nav from './components/Nav/Nav';
import BleuHoldingsLogo from './components/BleuHoldingsLogo';
import Illustration from './components/Illustration/Illustration';
import Motto from './components/Motto';
import IconDottics from './components/icons/IconDottics';

import './App.css';

function App() {
    return (
        <div className="App">
            <header>
                <Nav />
            </header>
            <main>
                <section className="logo-section">
                    <div className="logo">
                        <BleuHoldingsLogo />
                    </div>
                    <div>
                        <Motto />
                    </div>
                </section>
                <section className="illustration-section">
                    <Illustration />
                </section>
            </main>
            <footer>
                <div>powered by</div>
                <div className="footer">
                    <a
                        href="https://dottics.com"
                        target="_blank"
                        rel="opener noreferrer"
                    >
                        <div>&copy;</div>
                        <div className="dottics-logo">
                            <IconDottics />
                        </div>
                        <div>(PTY) LTD 2022</div>
                    </a>
                </div>
            </footer>
        </div>
    );
}

export default App;
