import React from 'react';

import IconBleuHoldings from '../icons/IconBleuHoldings';

import './Illustration.css';

const Illustration: React.FunctionComponent = () => {

    const lines = () => {
        let line = '';
        for (let i = 0; i < 100; i++) {
            line += '-';
        }
        return line;
    }

    return (
        <div className="illustration">
            <div className="left side">
                <div>Tenant</div>
                <div className="arrow">
                    <div>&lt;</div>
                    <div className="line">{lines()}</div>
                    <div>&gt;</div>
                </div>
            </div>
            <div className="shadow box outer-box">
                <div className="shadow box inner-box">
                    <IconBleuHoldings />
                </div>
            </div>
            <div className="right side">
                <div className="arrow">
                    <div>&lt;</div>
                    <div className="line">{lines()}</div>
                    <div>&gt;</div>
                </div>
                <div className="user">
                    <span>Agent</span>
                    <span className="cap">and</span>
                    <span>Owner</span>
                </div>
            </div>
        </div>
    );
};

export default Illustration